.App {
  text-align: center;
  color: #555555;
  background-color: #555555;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.shoveTabLeft {
  margin-left:auto;
}

.nav-link {
  color: #FFFFFF;
  font-weight: 600;
  min-width: 175px;
}

.nav-link:hover {
  color: #FFFFFF;
  background-color: #555555;
}


a {
  color: #FFFFFF;
  width: 175px;
  text-align: center;
}

a:hover {
  color: #FFFFFF;
  background-color: #555555;
}

.nav-tabs {
  border-bottom: 0;
  font-weight: 600;
}

.nav-link {
  font-style: normal;
}

.nav-link:hover {
  border: 0;
}

.row {
  margin: 0;
}

.wrapper {
  padding: 0;
}

.form-control:focus {
  outline: 0;
  box-shadow: 0px 0px 0px 0.2rem  rgba(169,171,45,0.7);
}

