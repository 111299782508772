.card {
    background-color: #A0A0A0;
    height: 100%;
    max-height: 575px;
}
.card-body {
    height: 501px;
    align-self: center;
}

.row {
    margin: 0;
}

.input-group {
    background-color: #222222;
    color: #FFFFFF;
}

.form-control {
    background-color: #222222 !important;
    color: #FFFFFF !important;
}

.form-control::placeholder {
    color: #FFFFFF !important;
}

.modal-body {
    background-color: #555555;
    color: #FFFFFF;
}

.modal-header {
    background-color: #222222;
    color: #FFFFFF;
}