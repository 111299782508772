$font-size: 16px;
$input-height: 2.5em; // at 16px, this an even 40px

.rw-widget-picker {
    background-color: #222222;
    color: #FFFFFF;
}

.rw-widget-input {
    background-color: #222222;
    color: #FFFFFF;
}

.rw-calendar {
    background-color: #555555;
    color: #FFFFFF;
}

.rw-calendar-row {
    background-color: #555555;
    color: #FFFFFF;
}

.rw-cell {
    background-color: #555555;
    color: #FFFFFF;
}

.rw-input {
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}

.rw-calendar-grid {
    background-color: #555555;
    color: #FFFFFF;
}

.rw-btn {
    color: #FFFFFF;
}

.rw-select:hover {
    background-color: #555555 !important;
    color: #FFFFFF;
}

.rw-select:active {
    background-color: #555555 !important;
    color: #FFFFFF;
}

.rw-widget-container:hover {
    background-color: #555555 !important;
    color: #FFFFFF;
}

.rw-widget-container:active {
    background-color: #555555 !important;
    color: #FFFFFF;
}

.rw-state-focus {
    background-color: #555555 !important;
    color: #FFFFFF;
}

.rw-list-option {
    background-color: #555555;
    color: #FFFFFF !important;
}

.rw-state-focus>.rw-widget-container,
.rw-state-focus>.rw-widget-container:hover {
    background-color: #555555;
    color: #FFFFFF;
    box-shadow: 0px 0px 0px 0.2rem rgba(169, 171, 45, 0.7) !important;
}