@import 'react-big-calendar/lib/sass/styles';
//@import 'react-big-calendar/lib/addons/dragAndDrop/styles'; // if using DnD


.rbc-header {
    border: 0;
}

.rbc-time-header {
    border: 0;
}

.rbc-time-view {
    border: 0;
}

.rbc-calendar {
    height:fit-content;
}

.rbc-allday-cell {
    border: 0;
}

.rbc-time-slot {
    background-color: #555555;
    border: 0 !important;
}


.rbc-time-header-gutter {
    background-color: #555555;
}

.rbc-event {
    color: #222222;
    background-color: #FFFFFF;
    border: 1 !important;
    border-color: #222222 !important;
}

.rbc-event:focus {
    outline: 5px auto #A9AB2D;
}

.rbc-event.rbc-selected{
    color: #FFFFFF !important;
    background-color: #222222 !important;
    border: 0 !important;
}

.rbc-event.rbc-selected.rbc-event-allday{
    color: #222222 !important;
    background-color: #FFFFFF !important;
    border: 0 !important;
}
.rbc-event.rbc-selected.rbc-event-allday:focus{
    outline: 5px auto #A9AB2D;
}

.rbc-today {
    background-color: #555555;
    color: #FFFFFF;
}

.rbc-btn-group {
    padding: 15px;
}

.rbc-btn-group button:hover {
    color: #222222 !important;
    background-color: #FFFFFF !important;
}

.rbc-btn-group .rbc-active {
    color: #FFFFFF !important;
    background-color: #222222 !important;
}

.rbc-current-time-indicator {
    height:3px;
    background-color: #A9AB2D;
}

.rbc-btn-group > button:first-child:not(:last-child) {
    color: #222222 ;
    background-color: #FFFFFF ;
}

.rbc-btn-group > button:not(:first-child):not(:last-child) {
    color: #222222 ;
    background-color: #FFFFFF ;
}

.rbc-btn-group > button:last-child:not(:first-child) {
    color: #222222 ;
    background-color: #FFFFFF ;
}

.rbc-time-header-content {
    border-left: 0;
}