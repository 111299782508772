@use './variables.scss' as *;

@mixin NumberPicker() {
  .rw-number-picker-spinners {
    display: flex;
    flex-direction: column;

    .rw-btn {
      flex: 1 1 0;
    }
  }
}
