.tablerow {
    padding: 15px;
}

thead {
    background-color: #222222;
    color: #FFFFFF;
}

tr {
    color: #FFFFFF;
    font-weight: 400;
}

.table-hover tbody tr:hover
{
    color: #222222 !important;
    background-color: #FFFFFF !important;
}

.input-group {
    background-color: #222222;
    color: #FFFFFF;
}

.form-control {
    background-color: #222222 !important;
    color: #FFFFFF !important;
}

.form-control::placeholder {
    color: #FFFFFF !important;
}

.modal-body {
    background-color: #555555;
    color: #FFFFFF;
}

.modal-header {
    background-color: #222222;
    color: #FFFFFF;
}

.pageButton {
    background-color: #444444 !important;
    border: 0px !important;
    color: #555555;
    padding-bottom: 15px !important;
}

.pageButton:focus {
    box-shadow: 0px 0px 0px 0rem  rgba(169,171,45,0.7) !important;
}

.pageButton:disabled{
    background-color: #555555 !important;
}

.pageText {
    padding: 15px !important;
}

.mutedText {
    color: #FFFFFF;
    font-weight: 100;
}

tr > th:first-child {
    display: none;
}

tr.bodyrow > td:first-child {
    display: none;
}

.col-md-6 {
    padding: 0px !important;
}

.filter-text > input {
    background-color: #555555;
    color: #FFFFFF;
}

th {
    vertical-align: baseline !important;
}